form {
  width: 105%;
  padding: 50px 30px;
  border-radius: 10px;
}

.label-container {
  width: 45%;
  position: relative;
  height: 50px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.432);
  border-radius: 7px;
}

.long {
  width: 100%;
}

.form-row {
  margin-top: 20px;
}

.form-row-1 {
  display: flex;
  justify-content: space-between;
}

form input {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 10px;
  color: white;
  background-color: #0c0c0c00;
  outline: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

form textarea {
  width: 100%;
  min-height: 150px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: 10px 10px;
  font-size: 14px;
  outline: none;
  font-family: "Montserrat", sans-serif;
}

form p {
  color: rgba(255, 255, 255, 0.432);
  margin-top: 30px;
}

.text-area {
  border: 1px solid rgba(255, 255, 255, 0.432);
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

.content-name {
  color: rgba(255, 255, 255, 0.432);
  position: absolute;
  bottom: 15px;
  padding-left: 10px;
  left: 0;
  transition: all 0.3s ease;
}

.submit-button {
  outline: none;
  margin-top: 40px;
  border: none;
  background-color: rgba(240, 255, 255, 0.288);
  border-radius: 5px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.678);
  font-size: 18px;
  height: 45px;
  width: 20%;
  text-align: center;
  transition: 1s ease-in;
}

.submit-button:hover {
  background-color: beige;
  color: black;
}

/* form label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.644);
} */

/* form label::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid rgb(255, 255, 255);
  transform: translate(-100%);
  transition: all 0.3s ease;
} */

form input:focus + .label-name .content-name,
form input:valid + .label-name .content-name {
  transform: translateY(-160%);
  font-size: 10px;
}

form input:focus + .label-name::after,
form input:valid + .label-name::after {
  transform: translateX(0%);
}

/* Medium Phone */
@media (max-width: 600px) {
  form {
    width: 100%;
    padding: 50px 0px;
  }

  .submit-button {
    width: 30%;
  }
}
