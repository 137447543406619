.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}
.menu-options {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigation-title {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: white;
  max-width: 30px;
}

a:hover {
  font-style: italic;
}

.burger {
  display: none;
}

.disappear {
  display: none;
}

.active {
  margin-right: 0px;
}

.nav-links a {
  margin-left: 30px;
}

@media (max-width: 768px) {
  .nav-links {
    position: sticky;
    display: flex;
    margin-top: -10px;
    margin-left: -150px;
    z-index: 100;
    height: 10vh;
    background-color: black;
    transition: 0.5s;
  }

  .active {
    margin-right: 200%;
  }

  .menu-options {
    width: 150%;
    justify-content: space-around;
  }
  .nav-links a {
    background-color: black;
    text-align: center;
    padding-top: 30px;
    margin-right: 10px;
  }
  .burger {
    display: block;
    position: absolute;
    right: 0px;
  }
}

@media (max-width: 380px) {
  .nav-links {
    padding-top: 50px;
    margin-left: -60px;
    z-index: 100;
    height: 10vh;

    background-color: black;
    transition: 0.5s;
  }

  .nav-links a {
    margin-left: 10px;
  }

  .active {
    margin-right: 250%;
  }
}
