.footer {
  display: flex;
  justify-content: center;
  padding: 0px 50px;
  color: rgba(255, 255, 255, 0.397);
  align-items: center;
  margin-bottom: 20px;
}

.footer p {
  font-size: 14px;
  margin-right: 10px;
  font-size: 12px;
}
