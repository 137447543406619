@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.banner-overlay {
  z-index: 1 !important;
  min-width: 100%;
  min-height: 100%;
  background-color: rgb(0, 0, 0);
}

body {
  background: #080808;
}

.banner-content {
  position: absolute;
  margin-top: 10px;
  width: 100%;
}

.banner-title {
  color: white;
  text-align: center;
  font-size: 70px;
  margin-top: 130px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.banner-desc {
  font-size: 18px;
  margin-top: -40px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  letter-spacing: 9px;
  font-weight: 400;
  text-align: center;
}

.banner-desc2 {
  font-family: "Montserrat", sans-serif;
  color: white;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 50%;
  font-weight: 200;
  text-align: center;
}

.navigation {
  margin: 20px 40px 0px 40px;
}

.menu {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  z-index: 100;
  max-width: 20%;
}

.menu a {
  text-decoration: none;
  margin-bottom: 10px;
  color: aliceblue;
  font-size: 18px;
  width: 20%;

  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  text-align: left;
  margin-left: 0;
  transition: 0.5s;
}
.menu:hover ~ .cursor {
  -webkit-transform: scale(5);
          transform: scale(5);
  mix-blend-mode: difference;
}

.menu a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.cursor {
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: fixed;
  border-radius: 50%;
  pointer-events: none;
}

.social-menu {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  right: 50px;
  min-width: 20%;
  position: absolute;
  top: 0;
}

.social-menu a:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}

.social-menu a {
  text-decoration: none;
  color: aliceblue;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  text-align: right;
  transition: 1s;
}

.discover-button {
  margin-top: 100px;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discover-button a {
  font-size: 25px;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.discover-button a:hover {
  background-color: rgba(0, 0, 0, 0.534);
}

/* Medium Phone */
@media (max-width: 550px) {
  .discover-button {
    margin-top: 80px;
  }

  .cursor {
    display: none;
  }

  .banner-title {
    letter-spacing: 10px;
    font-size: 65px;
    margin-bottom: 60px;
  }
  .banner-desc {
    font-style: initial;
  }
}

/* Small Phone */
@media (max-width: 380px) {
  .discover-button {
    margin-top: 30px;
    font-size: 20px;
  }

  .cursor {
    display: none;
  }

  .banner-title {
    font-size: 45px;
    margin-bottom: 100px;
  }

  .banner-desc {
    font-size: 15px;
  }
}

.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}
.menu-options {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navigation-title {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: white;
  max-width: 30px;
}

a:hover {
  font-style: italic;
}

.burger {
  display: none;
}

.disappear {
  display: none;
}

.active {
  margin-right: 0px;
}

.nav-links a {
  margin-left: 30px;
}

@media (max-width: 768px) {
  .nav-links {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    margin-top: -10px;
    margin-left: -150px;
    z-index: 100;
    height: 10vh;
    background-color: black;
    transition: 0.5s;
  }

  .active {
    margin-right: 200%;
  }

  .menu-options {
    width: 150%;
    justify-content: space-around;
  }
  .nav-links a {
    background-color: black;
    text-align: center;
    padding-top: 30px;
    margin-right: 10px;
  }
  .burger {
    display: block;
    position: absolute;
    right: 0px;
  }
}

@media (max-width: 380px) {
  .nav-links {
    padding-top: 50px;
    margin-left: -60px;
    z-index: 100;
    height: 10vh;

    background-color: black;
    transition: 0.5s;
  }

  .nav-links a {
    margin-left: 10px;
  }

  .active {
    margin-right: 250%;
  }
}

.footer {
  display: flex;
  justify-content: center;
  padding: 0px 50px;
  color: rgba(255, 255, 255, 0.397);
  align-items: center;
  margin-bottom: 20px;
}

.footer p {
  font-size: 14px;
  margin-right: 10px;
  font-size: 12px;
}

.portfolio-container {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.portfolio-title {
  color: white;
  font-size: 60px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  margin: 40px 0px 30px 0px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 65px;
  gap: 65px;
}

.portfolio-item {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  color: white;
  margin-left: 20px;
}

.img-container {
  min-width: 47%;
  max-width: 47%;
}

.portfolio-item img {
  width: 100%;
  height: 270px;
  border-radius: 5px;
}

.desc-container {
  margin-left: 30px;
}

.portfolio-item h1 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.artist {
  margin-top: -10px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.port-extra {
  width: 80%;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.692);
  margin-bottom: 98px;
}

.listen-btn {
  text-align: center;
  display: inline;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.listen-btn a:hover {
  color: white !important;
}

/* Medium Phone */
@media (max-width: 550px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .portfolio-title {
    font-size: 50px;
    margin-left: 20px;
  }
  .portfolio-item {
    flex-direction: column;
  }

  .img-container {
    min-width: 80%;
  }

  .desc-container {
    margin-left: 0px;
  }

  .port-extra {
    margin-bottom: 30px;
  }
}

@media (max-width: 380px) {
  .portfolio-title {
    font-size: 40px;
  }
}

.services-container {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
  position: relative;
}

.service-title {
  color: white;
  font-size: 60px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  margin: 40px 0px 30px 15px;
}

.services-container img {
  max-width: 100%;
  min-width: 100%;
  max-height: 400px;
  margin-top: -40px;
  border-radius: 40px;
}

.grid-container {
  display: flex;
  justify-content: center;
}

.service-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  color: rgba(255, 255, 255, 0.747);
  width: 60%;
  margin-top: 150px;
}

.service-item {
  text-align: center;
  max-width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.377);
  border-right: 0.5px solid rgba(255, 255, 255, 0.377);
  border-top: 0.5px solid rgba(255, 255, 255, 0.377);
  border-left: 0.5px solid rgba(255, 255, 255, 0.377);
  border-radius: 20px;
  padding: 3rem 2rem;
}

.service-item:nth-child(-2n + 3) {
  border-left: 0.5px solid rgba(255, 255, 255, 0.377);
}

.service-items h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
}

.divider {
  width: 1px;
  min-height: 140%;
  background-color: rgba(255, 255, 255, 0.404);
  margin: 25px 0px;
}

.service-item p {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: lighter;
  line-height: 20px;
  text-align: center;
}

.last {
  margin-top: 38px;
}

.col-divider {
  width: 70%;
  min-height: 1px;
  background-color: rgba(255, 255, 255, 0.404);
  margin: 0 auto;
  margin-top: 30px;
}

.contact-me {
  margin: 0;
}

.contact-desc {
  color: rgba(255, 255, 255, 0.815);
}

.contact-desc p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
}

.port-img {
  width: 38%;
  margin: 0 auto;
  padding-left: 230px;
  margin-top: 200px;
}

.portfolio-link-container {
  margin-bottom: 150px;
}

.port-background {
  background-color: grey;
  position: absolute;
  bottom: -120px;
  height: 19%;
  border-radius: 40px;
  margin-left: 280px;
  width: 40%;
  z-index: -10;
  transition: 2s ease-in;
}

.link-content {
  color: white;
  font-family: "Montserrat", sans-serif;
  position: relative;
  margin-top: -300px;
  width: 24%;
  max-width: 300px;
  font-size: 24px;
  font-weight: bold;
  margin-left: 350px;
}

.port-link {
  text-decoration: none;
  color: white;
}

.end {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.service-social a {
  color: gray;
}

.service-social a:hover {
  color: white !important;
}

.end p {
  margin-bottom: 10px;
  max-width: 50%;
  text-align: center;
}

.contact-btn {
  border: 1px solid white;
  padding: 15px 70px;
  text-decoration: none;
  color: white;
}

/* Medium Phone */
@media (max-width: 600px) {
  .contact-desc p {
    width: 90%;
  }

  .service-title {
    font-size: 50px;
  }

  .service-items {
    max-width: 90%;
    min-width: 90%;
  }

  .end p {
    max-width: 80%;
  }

  .link-content {
    margin-left: 10px;
    width: 50%;
  }
}

@media (max-width: 750px) {
  .service-items {
    grid-template-columns: repeat(1, 1fr);
  }

  .service-item {
    max-width: 85%;
    min-width: 85%;
  }

  .port-background {
    margin-left: 20px;
    height: 12%;
    width: 60%;
    bottom: -60px;
  }
  .port-img {
    padding-left: 0px;
    height: 5%;
    width: 60%;
  }

  .link-content {
    margin-left: 100px;
    width: 50%;
  }
}

@media (max-width: 1125px) {
  .service-items {
    max-width: 90%;
    min-width: 90%;
  }
}

@media (max-width: 380px) {
  .service-title {
    font-size: 40px;
  }
}

.contact-container {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.contact-body {
  display: flex;
  justify-content: space-between;
}

.contact-body img {
  width: 42%;
  height: 540px;
  margin-top: 50px;
  border-radius: 10px;
}

.contact-title {
  color: rgba(255, 255, 255, 0.815);
  font-size: 60px;
  font-weight: bolder !important;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  margin: 40px 0px 30px 15px;
}

.contact-me {
  color: white;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  margin-left: 290px;
  position: relative;
  top: -30px;
}

.socials {
  color: rgba(255, 255, 255, 0.342);
  position: relative;
}

.socials a {
  margin-left: 20px;
  font-size: 18px;
  float: right;
  color: gray;
}

.socials a:hover {
  color: white;
}

/* Medium Phone */
@media (max-width: 600px) {
  .contact-body {
    flex-direction: column;
    align-items: center;
  }

  .contact-title {
    font-size: 50px;
    margin-left: 20px;
  }

  .contact-me {
    margin-left: 20px;
  }

  .contact-body img {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 1000px) {
  .contact-body img {
    display: none;
  }
}

form {
  width: 105%;
  padding: 50px 30px;
  border-radius: 10px;
}

.label-container {
  width: 45%;
  position: relative;
  height: 50px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.432);
  border-radius: 7px;
}

.long {
  width: 100%;
}

.form-row {
  margin-top: 20px;
}

.form-row-1 {
  display: flex;
  justify-content: space-between;
}

form input {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 10px;
  color: white;
  background-color: #0c0c0c00;
  outline: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

form textarea {
  width: 100%;
  min-height: 150px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  padding: 10px 10px;
  font-size: 14px;
  outline: none;
  font-family: "Montserrat", sans-serif;
}

form p {
  color: rgba(255, 255, 255, 0.432);
  margin-top: 30px;
}

.text-area {
  border: 1px solid rgba(255, 255, 255, 0.432);
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

.content-name {
  color: rgba(255, 255, 255, 0.432);
  position: absolute;
  bottom: 15px;
  padding-left: 10px;
  left: 0;
  transition: all 0.3s ease;
}

.submit-button {
  outline: none;
  margin-top: 40px;
  border: none;
  background-color: rgba(240, 255, 255, 0.288);
  border-radius: 5px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.678);
  font-size: 18px;
  height: 45px;
  width: 20%;
  text-align: center;
  transition: 1s ease-in;
}

.submit-button:hover {
  background-color: beige;
  color: black;
}

/* form label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.644);
} */

/* form label::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid rgb(255, 255, 255);
  transform: translate(-100%);
  transition: all 0.3s ease;
} */

form input:focus + .label-name .content-name,
form input:valid + .label-name .content-name {
  -webkit-transform: translateY(-160%);
          transform: translateY(-160%);
  font-size: 10px;
}

form input:focus + .label-name::after,
form input:valid + .label-name::after {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

/* Medium Phone */
@media (max-width: 600px) {
  form {
    width: 100%;
    padding: 50px 0px;
  }

  .submit-button {
    width: 30%;
  }
}

