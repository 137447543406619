.portfolio-container {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.portfolio-title {
  color: white;
  font-size: 60px;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  margin: 40px 0px 30px 0px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 65px;
}

.portfolio-item {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  color: white;
  margin-left: 20px;
}

.img-container {
  min-width: 47%;
  max-width: 47%;
}

.portfolio-item img {
  width: 100%;
  height: 270px;
  border-radius: 5px;
}

.desc-container {
  margin-left: 30px;
}

.portfolio-item h1 {
  font-size: 25px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

.artist {
  margin-top: -10px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.port-extra {
  width: 80%;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.692);
  margin-bottom: 98px;
}

.listen-btn {
  text-align: center;
  display: inline;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.listen-btn a:hover {
  color: white !important;
}

/* Medium Phone */
@media (max-width: 550px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .portfolio-title {
    font-size: 50px;
    margin-left: 20px;
  }
  .portfolio-item {
    flex-direction: column;
  }

  .img-container {
    min-width: 80%;
  }

  .desc-container {
    margin-left: 0px;
  }

  .port-extra {
    margin-bottom: 30px;
  }
}

@media (max-width: 380px) {
  .portfolio-title {
    font-size: 40px;
  }
}
