.contact-container {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
}

.contact-body {
  display: flex;
  justify-content: space-between;
}

.contact-body img {
  width: 42%;
  height: 540px;
  margin-top: 50px;
  border-radius: 10px;
}

.contact-title {
  color: rgba(255, 255, 255, 0.815);
  font-size: 60px;
  font-weight: bolder !important;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  margin: 40px 0px 30px 15px;
}

.contact-me {
  color: white;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  margin-left: 290px;
  position: relative;
  top: -30px;
}

.socials {
  color: rgba(255, 255, 255, 0.342);
  position: relative;
}

.socials a {
  margin-left: 20px;
  font-size: 18px;
  float: right;
  color: gray;
}

.socials a:hover {
  color: white;
}

/* Medium Phone */
@media (max-width: 600px) {
  .contact-body {
    flex-direction: column;
    align-items: center;
  }

  .contact-title {
    font-size: 50px;
    margin-left: 20px;
  }

  .contact-me {
    margin-left: 20px;
  }

  .contact-body img {
    width: 100%;
    margin-bottom: 30px;
    margin-left: 20px;
  }
}

@media (max-width: 1000px) {
  .contact-body img {
    display: none;
  }
}
