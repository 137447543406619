@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import "aos/dist/aos.css";

.banner-overlay {
  z-index: 1 !important;
  min-width: 100%;
  min-height: 100%;
  background-color: rgb(0, 0, 0);
}

body {
  background: #080808;
}

.banner-content {
  position: absolute;
  margin-top: 10px;
  width: 100%;
}

.banner-title {
  color: white;
  text-align: center;
  font-size: 70px;
  margin-top: 130px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.banner-desc {
  font-size: 18px;
  margin-top: -40px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  letter-spacing: 9px;
  font-weight: 400;
  text-align: center;
}

.banner-desc2 {
  font-family: "Montserrat", sans-serif;
  color: white;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 50%;
  font-weight: 200;
  text-align: center;
}

.navigation {
  margin: 20px 40px 0px 40px;
}

.menu {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  z-index: 100;
  max-width: 20%;
}

.menu a {
  text-decoration: none;
  margin-bottom: 10px;
  color: aliceblue;
  font-size: 18px;
  width: 20%;

  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  text-align: left;
  margin-left: 0;
  transition: 0.5s;
}
.menu:hover ~ .cursor {
  transform: scale(5);
  mix-blend-mode: difference;
}

.menu a:hover {
  transform: scale(1.1);
}
.cursor {
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: fixed;
  border-radius: 50%;
  pointer-events: none;
}

.social-menu {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  right: 50px;
  min-width: 20%;
  position: absolute;
  top: 0;
}

.social-menu a:hover {
  transform: scale(1.03);
}

.social-menu a {
  text-decoration: none;
  color: aliceblue;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  text-align: right;
  transition: 1s;
}

.discover-button {
  margin-top: 100px;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discover-button a {
  font-size: 25px;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.discover-button a:hover {
  background-color: rgba(0, 0, 0, 0.534);
}

/* Medium Phone */
@media (max-width: 550px) {
  .discover-button {
    margin-top: 80px;
  }

  .cursor {
    display: none;
  }

  .banner-title {
    letter-spacing: 10px;
    font-size: 65px;
    margin-bottom: 60px;
  }
  .banner-desc {
    font-style: initial;
  }
}

/* Small Phone */
@media (max-width: 380px) {
  .discover-button {
    margin-top: 30px;
    font-size: 20px;
  }

  .cursor {
    display: none;
  }

  .banner-title {
    font-size: 45px;
    margin-bottom: 100px;
  }

  .banner-desc {
    font-size: 15px;
  }
}
