.services-container {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
  position: relative;
}

.service-title {
  color: white;
  font-size: 60px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  margin: 40px 0px 30px 15px;
}

.services-container img {
  max-width: 100%;
  min-width: 100%;
  max-height: 400px;
  margin-top: -40px;
  border-radius: 40px;
}

.grid-container {
  display: flex;
  justify-content: center;
}

.service-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  color: rgba(255, 255, 255, 0.747);
  width: 60%;
  margin-top: 150px;
}

.service-item {
  text-align: center;
  max-width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.377);
  border-right: 0.5px solid rgba(255, 255, 255, 0.377);
  border-top: 0.5px solid rgba(255, 255, 255, 0.377);
  border-left: 0.5px solid rgba(255, 255, 255, 0.377);
  border-radius: 20px;
  padding: 3rem 2rem;
}

.service-item:nth-child(-2n + 3) {
  border-left: 0.5px solid rgba(255, 255, 255, 0.377);
}

.service-items h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
}

.divider {
  width: 1px;
  min-height: 140%;
  background-color: rgba(255, 255, 255, 0.404);
  margin: 25px 0px;
}

.service-item p {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: lighter;
  line-height: 20px;
  text-align: center;
}

.last {
  margin-top: 38px;
}

.col-divider {
  width: 70%;
  min-height: 1px;
  background-color: rgba(255, 255, 255, 0.404);
  margin: 0 auto;
  margin-top: 30px;
}

.contact-me {
  margin: 0;
}

.contact-desc {
  color: rgba(255, 255, 255, 0.815);
}

.contact-desc p {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
}

.port-img {
  width: 38%;
  margin: 0 auto;
  padding-left: 230px;
  margin-top: 200px;
}

.portfolio-link-container {
  margin-bottom: 150px;
}

.port-background {
  background-color: grey;
  position: absolute;
  bottom: -120px;
  height: 19%;
  border-radius: 40px;
  margin-left: 280px;
  width: 40%;
  z-index: -10;
  transition: 2s ease-in;
}

.link-content {
  color: white;
  font-family: "Montserrat", sans-serif;
  position: relative;
  margin-top: -300px;
  width: 24%;
  max-width: 300px;
  font-size: 24px;
  font-weight: bold;
  margin-left: 350px;
}

.port-link {
  text-decoration: none;
  color: white;
}

.end {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.service-social a {
  color: gray;
}

.service-social a:hover {
  color: white !important;
}

.end p {
  margin-bottom: 10px;
  max-width: 50%;
  text-align: center;
}

.contact-btn {
  border: 1px solid white;
  padding: 15px 70px;
  text-decoration: none;
  color: white;
}

/* Medium Phone */
@media (max-width: 600px) {
  .contact-desc p {
    width: 90%;
  }

  .service-title {
    font-size: 50px;
  }

  .service-items {
    max-width: 90%;
    min-width: 90%;
  }

  .end p {
    max-width: 80%;
  }

  .link-content {
    margin-left: 10px;
    width: 50%;
  }
}

@media (max-width: 750px) {
  .service-items {
    grid-template-columns: repeat(1, 1fr);
  }

  .service-item {
    max-width: 85%;
    min-width: 85%;
  }

  .port-background {
    margin-left: 20px;
    height: 12%;
    width: 60%;
    bottom: -60px;
  }
  .port-img {
    padding-left: 0px;
    height: 5%;
    width: 60%;
  }

  .link-content {
    margin-left: 100px;
    width: 50%;
  }
}

@media (max-width: 1125px) {
  .service-items {
    max-width: 90%;
    min-width: 90%;
  }
}

@media (max-width: 380px) {
  .service-title {
    font-size: 40px;
  }
}
